import axiosMiddleware from "./axiosMiddleware";

export const getAgentsListAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "agent/list",
  });
};
export const editAgentDefaultWalletAPI = ({ id, currencies }) => {
  let postData = {
    id,
    currencies,
  };
  return axiosMiddleware({
    method: "patch",
    url: "/users/default/update",
    data: postData,
  });
};
export const deleteDefaultWalletAPI = ({ id }) => {
  let postData = {
    id,
  };
  return axiosMiddleware({
    method: "delete",
    url: "/users/default/delete",
    data: postData,
  });
};
export const getAgentTransactionsAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `agent/transaction/list?limit=${limit}&&offset=${offset}`,
    // data: postData,
  });
};

export const getAgentsBalancesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/agents/balance",
  });
};


export const updateAgentAPI = ({ agentId, updatedStatus }) => {
  let postData = {
    agentId,
    updatedStatus,
  };

  return axiosMiddleware({
    method: "post",
    url: "agent/update",
    data: postData,
  });
};