import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "components/CustomTable";
import {
  getAgentsListRequestAction,
  updateAgentRequestAction,
} from "redux/actions/agentActions";
import { CheckBox } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import { showMessage } from "components/Modal";
// --------------------------------------------------------------------------------
const AgentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { agentsList } = useSelector((state) => ({
    agentsList: state.agent.agentsList,
  }));

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, // Customize the default page size
  });

  useEffect(() => {
    dispatch(getAgentsListRequestAction());
  }, [dispatch]); // Ensure the agent list loads on component mount

  const handlePremiumToggle = (agentId, currentStatus) => {
    const updatedStatus = !currentStatus;

    dispatch(
      updateAgentRequestAction({
        agentId,
        updatedStatus,
        onSuccess: () => {
          showMessage({
            description: "Agent premium status updated successfully",
            status: "success",
          });
        },
      })
    );
  };

  const handleElitoToggle = (agentId, currentStatus) => {
    const updatedStatus = !currentStatus;

    dispatch(
      updateAgentRequestAction({
        agentId,
        updatedStatus,
        onSuccess: () => {
          showMessage({
            description: "Agent Elito status updated successfully",
            status: "success",
          });
        },
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 150,
        Cell: ({ row }) => row.original.name,
      },
      {
        accessorKey: "referralCode",
        header: "Referral Code",
        size: 200,
        Cell: ({ row }) => row.original.referral_code,
      },
      {
        accessorKey: "is_premium",
        header: "Is Premium",
        size: 150,
        Cell: ({ row }) => {
          const [isPremiumChecked, setIsPremiumChecked] = useState(row.original.is_premium);

          const handleCheckboxChange = () => {
            setIsPremiumChecked((prevState) => !prevState);
            handlePremiumToggle(row.original.id, isPremiumChecked);
          };

          return (
            <Checkbox
              checked={isPremiumChecked}
              onChange={handleCheckboxChange}
            />
          );
        },
      },
      {
        accessorKey: "is_from_elito",
        header: "Is From Elito",
        size: 150,
        Cell: ({ row }) => {
          const [isElitoChecked, setIsElitoChecked] = useState(row.original.is_from_elito);
          const handleCheckboxChange = () => {
            setIsElitoChecked((prevState) => !prevState);
            handleElitoToggle(row.original.id, isElitoChecked);
          };

          return (
            <Checkbox
              checked={isElitoChecked}
              onChange={handleCheckboxChange}
            />
          );
        },
      },
    ],
    [agentsList]
  );

  return (
    <CustomTable
      label="Agent List"
      data={agentsList}
      columns={columns}
      manualPagination={false}
    />
  );
};


export default AgentList;
