import {
  DELETE_DEFAULT_WALLET_FAILURE,
  DELETE_DEFAULT_WALLET_STARTED,
  DELETE_DEFAULT_WALLET_SUCCESS,
  GET_AGENT_TRANSACTIONS_FAILURE,
  GET_AGENT_TRANSACTIONS_STARTED,
  GET_AGENT_TRANSACTIONS_SUCCESS,
  GET_AGENTS_BALANCES_FAILURE,
  GET_AGENTS_BALANCES_STARTED,
  GET_AGENTS_BALANCES_SUCCESS,
  GET_AGENTS_LIST_FAILURE,
  GET_AGENTS_LIST_STARTED,
  GET_AGENTS_LIST_SUCCESS,
  SET_AGENT,
  UPDATE_AGENT_FAILURE,
  UPDATE_AGENT_STARTED,
  UPDATE_AGENT_SUCCESS,
} from "redux/ActionTypes";
import {
  deleteDefaultWalletAPI,
  editAgentDefaultWalletAPI,
  getAgentsBalancesAPI,
  getAgentsListAPI,
  getAgentTransactionsAPI,
  updateAgentAPI,
} from "services/API/agent";
import { getUserDefaultsRequestAction } from "./usersActions";
import { showMessage } from "components/Modal";
// --------------------------------------------------------------------------------
export const getAgentsListStartedAction = () => ({
  type: GET_AGENTS_LIST_STARTED,
});

export const getAgentsListSuccessAction = (payload) => ({
  type: GET_AGENTS_LIST_SUCCESS,
  payload,
});

export const getAgentsListFailureAction = (message) => ({
  type: GET_AGENTS_LIST_FAILURE,
  payload: message,
});

export const getAgentsListRequestAction = () => {
  return async (dispatch) => {
    dispatch(getAgentsListStartedAction());
    try {
      let response = await getAgentsListAPI();
      if (!response.error) {
        dispatch(getAgentsListSuccessAction({ agentsList: response.data }));
      } else {
        dispatch(getAgentsListFailureAction(response.data.error_description));
      }
    } catch (error) {
      console.log(error);
      dispatch(getAgentsListFailureAction(error.error_description));
    }
  };
};

// --------------------------------------------------------------------------------

export const setAgent = (agent) => ({
  type: SET_AGENT,
  payload: agent,
});

// --------------------------------------------------------------------------------
export const deleteDefaultWalletRequestAction = ({ id }) => {
  return async (dispatch) => {
    dispatch(deleteDefaultWalletStartedAction());
    try {
      let response = await deleteDefaultWalletAPI({ id });
      if (!response.error) {
        dispatch(deleteDefaultWalletSuccessAction());
        dispatch(getUserDefaultsRequestAction());
      } else {
        dispatch(
          deleteDefaultWalletFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(deleteDefaultWalletFailureAction(error.error_description));
    }
  };
};

export const deleteDefaultWalletStartedAction = () => ({
  type: DELETE_DEFAULT_WALLET_STARTED,
});

export const deleteDefaultWalletSuccessAction = (payload) => ({
  type: DELETE_DEFAULT_WALLET_SUCCESS,
  payload,
});

export const deleteDefaultWalletFailureAction = (message) => ({
  type: DELETE_DEFAULT_WALLET_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const editAgentDefaultWalletRequestAction = ({ id, currencies }) => {
  return async (dispatch) => {
    dispatch(editAgentDefaultWalletStartedAction());
    try {
      let response = await editAgentDefaultWalletAPI({ id, currencies });
      if (!response.error) {
        dispatch(editAgentDefaultWalletSuccessAction());
      } else {
        dispatch(
          editAgentDefaultWalletFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(editAgentDefaultWalletFailureAction(error.error_description));
    }
  };
};

export const editAgentDefaultWalletStartedAction = () => ({
  type: DELETE_DEFAULT_WALLET_STARTED,
});

export const editAgentDefaultWalletSuccessAction = (payload) => ({
  type: DELETE_DEFAULT_WALLET_SUCCESS,
  payload,
});

export const editAgentDefaultWalletFailureAction = (message) => ({
  type: DELETE_DEFAULT_WALLET_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getAgentTransactionsRequestAction = ({ limit, offset }) => {
  return async (dispatch) => {
    dispatch(getAgentTransactionsStartedAction());
    try {
      let response = await getAgentTransactionsAPI({ limit, offset });
      if (!response.error) {
        dispatch(
          getAgentTransactionsSuccessAction({
            transactions: response.data,
          })
        );
      } else {
        dispatch(
          getAgentTransactionsFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getAgentTransactionsFailureAction(error.error_description));
    }
  };
};

export const getAgentTransactionsStartedAction = () => ({
  type: GET_AGENT_TRANSACTIONS_STARTED,
});

export const getAgentTransactionsSuccessAction = (payload) => ({
  type: GET_AGENT_TRANSACTIONS_SUCCESS,
  payload,
});

export const getAgentTransactionsFailureAction = (message) => ({
  type: GET_AGENT_TRANSACTIONS_FAILURE,
  payload: message,
});

export const getAgentsBalancesAction = ({ setBalances }) => {
  return async (dispatch) => {
    dispatch(getAgentsStartedAction());
    try {
      let response = await getAgentsBalancesAPI();
      if (!response.error) {
        dispatch(getAgentsSuccessAction());
        setBalances(response.data);
      } else {
        dispatch(getAgentsFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getAgentsFailureAction(error.error_description));
    }
  };
};

export const getAgentsStartedAction = () => ({
  type: GET_AGENTS_BALANCES_STARTED,
});

export const getAgentsSuccessAction = (payload) => ({
  type: GET_AGENTS_BALANCES_SUCCESS,
  payload,
});

export const getAgentsFailureAction = (message) => ({
  type: GET_AGENTS_BALANCES_FAILURE,
  payload: message,
});

// --------------------------------------------------------------------------------
export const updateAgentRequestAction = ({
  agentId,
  updatedStatus,
  onSuccess,
}) => {
  return async (dispatch) => {
    dispatch(updateAgentStartedAction());
    try {
      let response = await updateAgentAPI({
        agentId,
        updatedStatus,
        onSuccess,
      });
      if (!response.error) {
        dispatch(updateAgentSuccessAction());
        onSuccess();
      } else {
        dispatch(updateAgentFailureAction(response.data.error_description));
      }
    } catch (error) {
      showMessage({
        description: error.error_description,
        status: "error",
      });
      dispatch(updateAgentFailureAction(error.error_description));
    }
  };
};

export const updateAgentStartedAction = () => ({
  type: UPDATE_AGENT_STARTED,
});

export const updateAgentSuccessAction = (payload) => ({
  type: UPDATE_AGENT_SUCCESS,
  payload,
});

export const updateAgentFailureAction = (message) => ({
  type: UPDATE_AGENT_FAILURE,
  payload: message,
});
