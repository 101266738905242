import APIs from "services/API/index";

export const formItems = [
  {
    name: "name",
    type: "text",
    label: "Name",
    values: null,
    required: true,
  },
  {
    name: "referralCode",
    type: "text",
    label: "Referral Code",
    values: null,
    required: true,
  },
  {
    name: "is_premium",
    type: "checkbox",
    label: "Make Premium",
    checked: false,
    margin : '27px',
  },
  {
    name: "is_from_elito",
    type: "checkbox",
    label: "Mark as Elito Agent",
    checked: false,
    margin : '27px 0',
  },
];
