import React, { memo, forwardRef, useState, useEffect } from "react";
import AppTextInput from "components/AppTextInput/index";
import AppDropDown from "components/AppDropDown/index";
import { Formik } from "formik";
import { useCallback } from "react";
import AppButton from "components/AppButton/index";
import { string, object } from "yup";
import AppNumberInput from "components/AppNumberInput/index";
import Loading from "components/Loading/index";
import FileUploader from "components/FileUploader/index";
import Divider from "@mui/material/Divider";
import AppDatePicker from "components/AppDatePicker/index";
import AppSwitch from "components/AppSwitch/index";
import classNames from "classnames";
import { Grid } from "@mui/material";
import AppCheckbox from "components/AppCheckbox";

const FormGenerator = forwardRef(
  (
    {
      onSubmit,
      onCancel,
      formItems = [],
      initialValues,
      submitBtnComponent,
      defaultBtnText = "Submit",
      btnLoading,
      loading,
      customClassName,
      getFormValues,
    },
    ref
  ) => {
    const [formValues, setformValues] = useState({});

    //console.log(initialValues)

    useEffect(() => {
      getFormValues?.(formValues);
    }, [formValues]);

    const renderFields = useCallback(
      ({
        formItems,
        setFieldValue,
        values,
        touched,
        setFieldTouched,
        errors,
      }) => {
        return formItems?.map((item) => {
          const { name, label, type , margin } = item;
          if (type === "row") {
            return (
              <div className="w-100" key={item?.key}>
                {renderFields({
                  formItems: item?.data,
                  setFieldValue,
                  values,
                  touched,
                  setFieldTouched,
                  errors,
                })}
              </div>
            );
          }
          if (type === "text")
            return (
              <Grid item xs={12} md={3}>
                <AppTextInput
                  {...item}
                  name={name}
                  key={name}
                  type={type}
                  label={label}
                  pattern={item?.regex}
                  maxLength={item?.max}
                  required={item?.required}
                  value={values[name]}
                  onChange={(e) => setFieldValue(name, e.target.value)}
                  error={errors[name] && touched[name]}
                  onFocus={() => setFieldTouched(name, false)}
                  onBlur={() => setFieldTouched(name)}
                  hidden={item?.hidden}
                  fullWidth
                />
              </Grid>
            );

             if (type === "password")
            return (
              <Grid item xs={12} md={3}>
                <AppTextInput
                  {...item}
                  name={name}
                  key={name}
                  type={type}
                  label={label}
                  pattern={item?.regex}
                  maxLength={item?.max}
                  required={item?.required}
                  value={values[name]}
                  onChange={(e) => setFieldValue(name, e.target.value)}
                  error={errors[name] && touched[name]}
                  onFocus={() => setFieldTouched(name, false)}
                  onBlur={() => setFieldTouched(name)}
                  hidden={item?.hidden}
                  fullWidth
                />
              </Grid>
            );
          if (type === "textarea")
            return (
              <div
                style={{
                  order: formItems.length,
                }}
              >
                <AppTextInput
                  {...item}
                  name={name}
                  key={name}
                  type={type}
                  label={label}
                  pattern={item?.regex}
                  maxLength={item?.max}
                  required={item?.required}
                  value={values[name]}
                  onChange={(e) => setFieldValue(name, e.target.value)}
                  error={errors[name] && touched[name]}
                  onFocus={() => setFieldTouched(name, false)}
                  onBlur={() => setFieldTouched(name)}
                  multiline
                  hidden={item?.hidden}
                />
              </div>
            );

          if (type === "number") {
            return (
              <AppNumberInput
                {...item}
                name={name}
                key={name}
                type={type}
                label={label}
                maxLength={item?.max}
                required={item?.required}
                value={values[name]}
                onChange={(event) => {
                  setFieldValue(name, event.target.value);
                  item?.onChange?.({ event, setFieldValue });
                }}
                error={errors[name] && touched[name]}
                onFocus={() => {
                  setFieldTouched(name, false);
                  item?.onFocus?.(setFieldValue);
                }}
                onBlur={() => setFieldTouched(name)}
                readOnly={item?.readOnly || values.readOnlyField === name}
              />
            );
          }
          if (type === "select")
            return (
              <AppDropDown
                name={name}
                key={name}
                type={type}
                label={label}
                items={item?.values}
                schema={item?.schema ?? { label: "name", value: "value" }}
                value={values[name]}
                onChange={(e) => setFieldValue(name, e.target.value)}
                error={errors[name] && touched[name]}
                onFocus={() => setFieldTouched(name, false)}
                onBlur={() => setFieldTouched(name)}
                apiAddress={item?.apiAddress}
                dependValue={
                  item?.dependValue && values?.[item?.dependValue]
                    ? values[item?.dependValue]
                    : null
                }
                dependKey={item?.dependKey ?? item?.dependValue}
                keyMap={item?.keyMap}
                filterItemByValue={values?.[item?.filterItemByValue]}
                filterItemByKey={item?.filterItemByKey}
                requestOnMount={item?.requestOnMount}
                extraItem={item?.extraItem}
                disabled={item?.disabled}
              />
            );
          if (type === "multipleSelect")
            return (
              <Grid item xs={12} md={3}>
                <AppDropDown
                  name={name}
                  key={name}
                  type={type}
                  label={label}
                  items={item?.values}
                  schema={item?.schema ?? { label: "name", value: "value" }}
                  error={errors[name] && touched[name]}
                  onFocus={() => setFieldTouched(name, false)}
                  onBlur={() => setFieldTouched(name)}
                  apiAddress={item?.apiAddress}
                  dependValue={
                    item?.dependValue && values?.[item?.dependValue]
                      ? values[item?.dependValue]
                      : null
                  }
                  dependKey={item?.dependKey ?? item?.dependValue}
                  keyMap={item?.keyMap}
                  filterItemByValue={values?.[item?.filterItemByValue]}
                  filterItemByKey={item?.filterItemByKey}
                  requestOnMount={item?.requestOnMount}
                  extraItem={item?.extraItem}
                  disabled={item?.disabled}
                  SelectProps={{
                    multiple: true,
                    value: values[name],
                    onChange: (e) => setFieldValue(name, e.target.value),
                  }}
                  fullWidth
                />
              </Grid>
            );
          if (type === "switch")
            return (
              <AppSwitch
                name={name}
                key={name}
                label={label}
                checked={values[name]}
                onChange={(e) => setFieldValue(name, e.target.checked)}
              />
            );

          if (type === "file")
            return (
              <div className="w-100">
                <FileUploader
                  {...item}
                  name={name}
                  onChange={(e) => setFieldValue(name, e.target.files[0])}
                  file={values[name]}
                  error={errors[name] && touched[name]}
                  onFocus={() => setFieldTouched(name, false)}
                  onBlur={() => setFieldTouched(name)}
                />
              </div>
            );

          if (type === "datePicker")
            return (
              <AppDatePicker
                {...item}
                key={name}
                name={name}
                value={values?.[name]}
                label={label}
                onChange={(e) => setFieldValue(name, e)}
                error={errors[name] && touched[name]}
                defaultValue={item?.defaultValue}
                onFocus={() => setFieldTouched(name, false)}
                disablePast={item?.disablePast}
                disableFuture={item?.disableFuture}
              />
            );

            if (type === "checkbox")
              return (
                <AppCheckbox
                {...item}
                key={name}
                name={name}
                checked={values?.[name] || false}
                label={label}
                onChange={(e) => setFieldValue(name, e.target.checked)}
                error={Boolean(errors[name] && touched[name])}
                defaultValue={item?.defaultValue}
                onBlur={() => setFieldTouched(name, true)}
                disablePast={item?.disablePast}
                disableFuture={item?.disableFuture}
                margin = {margin}
              />
              );
          if (type === "divider")
            return <Divider className="my-3 d-block w-100" key="divider" />;
          return null;
        });
      },
      []
    );

    const initialize = { readOnlyField: "" };

    const temp = [];
    const renderValidationObject = (formItems) => {
      formItems?.map((item) => {
        if (item?.type === "divider") return [];
        if (item?.type === "row") return renderValidationObject(item?.data);

        if (item.name) {
          Object.assign(initialize, { [item?.name]: "" });
          temp.push([item?.name, item?.required ? string().required() : ""]);
          return [item?.name, item?.required ? string().required() : ""];
        }
        return [];
      });
      return Object.fromEntries(temp);
    };

    const validationSchema = object().shape(renderValidationObject(formItems));

    return (
      <>
        {loading && <Loading componentMode />}
        <Formik
          innerRef={ref}
          initialValues={{ ...initialize, ...initialValues }}
          onSubmit={(values, actions) => {
            const { readOnlyField, ...formValues } = values;
            onSubmit?.(formValues, actions);
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            values,
            setFieldValue,
            setFieldTouched,
            touched,
            errors,
            handleSubmit,
          }) => {
            setformValues(values);
            return (
              <>
                <Grid container spacing={3}>
                  {renderFields({
                    formItems,
                    values,
                    setFieldValue,
                    setFieldTouched,
                    errors,
                    touched,
                  })}
                </Grid>
                {submitBtnComponent ? (
                  <>{submitBtnComponent({ onClick: handleSubmit })}</>
                ) : (
                  <div className="d-flex justify-content-center w-100 mt-5">
                    {onCancel && (
                      <AppButton
                        className="mr-2"
                        text="Cancel"
                        variant="outlined"
                        onClick={onCancel}
                      />
                    )}
                    <AppButton
                      text={defaultBtnText}
                      onClick={handleSubmit}
                      loading={btnLoading}
                    />
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </>
    );
  }
);

export default memo(FormGenerator);
