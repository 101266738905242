import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { superAdmin } from "constant";
import { GET_ELITO_PENDING_USERS } from "config/apiRoutes";
import PaginationTable from "components/PaginationTable";
import { LoadingButton } from "@mui/lab";
import { updateTopupCardStatusRequestAction } from "redux/actions/cardActions";
import { showMessage } from "components/Modal";
import { Box, Dialog } from "@mui/material";
// --------------------------------------------------------------------------------
const ElitoPendingUsers = () => {
  const dispatch = useDispatch();

  const roles = useSelector((state) => state.auth.user.roles);
  const permissions = useSelector((state) => state.auth.user.permissions);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedReferenceNumber, setSelectedReferenceNumber] = useState(null);

  const paginationTableRef = useRef();

  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, []);

  const handleOpenConfirmModal = (reference_number) => {
    setSelectedReferenceNumber(reference_number);
    setOpenConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setSelectedReferenceNumber(null);
  };

  const handleConfirmUpdate = () => {
    if (selectedReferenceNumber) {
      updateStatus(selectedReferenceNumber);
    }
    handleCloseConfirmModal();
  };

  const updateStatus = (reference_number) => {
    dispatch(
      updateTopupCardStatusRequestAction({
        reference_number,
        onSuccess: () => {
          showMessage({
            status: "success",
          });
          paginationTableRef.current.refetch();
        },
      })
    );
  };

  const columns = useMemo(() => {
    const baseColumns = [
      // {
      //   accessorKey: "userFullName",
      //   // accessorFn: (row) => `${row.firstName} ${row.lastName}`,
      //   header: "User's Full Name",
      //   size: 150,
      //   Cell: ({ cell }) => <span>{cell.getValue()}</span>,
      //   mergedKeys: ["firstName", "lastName"],
      // },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        Cell: ({ cell, row }) => {
          return permissions.some((p) => p === "See Profile User") &&
            row.original.pepStatus ? (
            <Link to={`/app/profile/${row.original.id}/Summary`}>
              {cell.getValue()}
            </Link>
          ) : (
            <span>{cell.getValue()}</span>
          );
        },
      },
      {
        accessorKey: "source_amount",
        header: "Source Amount",
        size: 150,
      },
      {
        accessorKey: "target_amount",
        header: "Target Amount",
        size: 150,
      },
      {
        accessorKey: "fee",
        header: "Fee",
        size: 150,
      },
      {
        accessorKey: "rate",
        header: "Rate",
        size: 150,
      },
      {
        accessorKey: "authority",
        header: "Authority",
        size: 150,
      },
      {
        accessorKey: "reference_number",
        header: "Reference Number",
        size: 150,
      },
      {
        accessorKey: "operation",
        header: "Operation",
        size: 150,
        Cell: ({ row }) => (
          <LoadingButton
            onClick={() =>
              handleOpenConfirmModal(row.original.reference_number)
            }
            variant="contained"
            color="primary"
          >
            Approve
          </LoadingButton>
        ),
      },
    ];

    return baseColumns;
  }, []);

  return (
    <>
      <PaginationTable
        ref={paginationTableRef}
        title="Elito Pending Users"
        apiEndpoint={GET_ELITO_PENDING_USERS}
        columns={columns}
      />
      <Dialog open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <Box p={3}>
          <h3>Confirm Action</h3>
          <p>Are you sure you want to update the status?</p>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <LoadingButton
              onClick={handleCloseConfirmModal}
              sx={{ color: "#757575", borderColor: "#757575" }}
              variant="outlined"
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={handleConfirmUpdate}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              Confirm
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ElitoPendingUsers;
