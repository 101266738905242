import React, { useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCardDetails,
  getUserTransactions,
} from "redux/actions/usersActions";
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Link,
  Button,
} from "@mui/material";
import { CardTitle } from "reactstrap";
import DataCard from "../../../../../components/DataCard";
import Dayjs from "dayjs";
import { getCardTransactions } from "redux/actions/usersActions";
import CardLimits from "./CardLimits";
import CardsStatus from "./CardsStatus";
import CustomTable from "components/CustomTable";
import withDateRangePicker from "HOC/withDateRangePicker";
import PaginationTable from "components/PaginationTable";
import { GET_CARD_TRANSACTION, GET_CARD_TRANSACTIONS } from "config/apiRoutes";
import { sendEmailAction } from "redux/actions/cardActions";
import { showMessage } from "components/Modal";
import HasPermissions from "components/HasPermissions";

// --------------------------------------------------------------------------------
const CardDetail = ({ from, to, renderTopToolbar }) => {
  const dispatch = useDispatch();
  const [card, setCard] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showLimitsAndStatus, setShow] = useState(true);
  const { id: cardId } = useParams();
  const [data, setData] = useState({ records: [] });
  const paginationTableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const permissions = useSelector((state) => state.auth.user.permissions);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  // const fetch = () => {
  //   dispatch(
  //     getUserTransactions({
  //       setData,
  //       cardId,
  //       from,
  //       to,
  //       limit: pagination.pageSize,
  //       offset: pagination.pageIndex + 1,
  //     })
  //   );
  // };

  useEffect(() => {
    if (cardId) dispatch(getCardDetails({ setCard, cardId }));
  }, [cardId]);

  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    if (card.type) {
      if (
        (card.type === "plastic" && card.status === "cancel") ||
        (card.type === "virtual" && card.status === "remove")
      ) {
        setShow(false);
      }
    }
  }, [card]);

  // useEffect(() => {
  //   dispatch(
  //     getCardTransactions({
  //       setData,
  //       cardId,
  //       from: Dayjs(from).format("YYYY-MM-DD"),
  //       to: Dayjs(to).format("YYYY-MM-DD"),
  //       limit: pagination.pageSize,
  //       offset: pagination.pageIndex + 1,
  //     })
  //   );
  // }, [pagination.pageIndex, pagination.pageSize, from, to]);

  const isPermission =
    permissions.includes("See Physical Cards") &&
    permissions.includes("See Virtual Cards");

  const columns = useMemo(
    () => [
      {
        accessorKey: "transaction_status",
        header: "Transaction Status",
      },
      {
        accessorKey: "transaction_amount",
        header: "Transaction Amount",
      },
      {
        accessorKey: "transaction_currency",
        header: "Transaction Currency",
      },
      {
        accessorKey: "terminal_type",
        header: "Terminal Type",
      },
      {
        accessorKey: "mcc",
        header: "MCC",
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "rrn",
        header: "RRN",
      },
      {
        accessorKey: "transaction_date_time",
        header: "Transaction Time",
      },
      {
        accessorKey: "card_holer_name",
        header: "Card Name",
      },
    ],
    []
  );

  return (
    <>
      <HasPermissions permissions="See Cards Detail">
        <Card sx={{ p: 2, mb: 2 }}>
          <CardTitle>Card Detail</CardTitle>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Grid sx={{ mb: 2 }}>
                  <Typography sx={{ display: "inline" }}>
                    Masked Card Number:{" "}
                  </Typography>
                  <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                    {card.cardNumber}
                  </Typography>
                </Grid>
                <Grid sx={{ mb: 2 }}>
                  <Typography sx={{ display: "inline" }}>
                    Card Type:{" "}
                  </Typography>
                  <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                    {card.type}
                  </Typography>
                </Grid>
                <Grid sx={{ mb: 2 }}>
                  <Typography sx={{ display: "inline" }}>
                    Card Brand:{" "}
                  </Typography>
                  <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                    {card.brand}
                  </Typography>
                </Grid>
                <Grid sx={{ mb: 2 }}>
                  <Typography sx={{ display: "inline" }}>
                    Card Status:{" "}
                  </Typography>
                  <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                    {card.status}
                  </Typography>
                </Grid>
                <Grid sx={{ mb: 2 }}>
                  <Typography sx={{ display: "inline" }}>
                    Created Time:{" "}
                  </Typography>
                  <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                    {card.createdTime}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid sx={{ mb: 2 }}>
                  <Typography sx={{ display: "inline" }}>
                    User's Full Name:{" "}
                  </Typography>
                  <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                    {isPermission ? (
                      <Link
                        target="_blank"
                        href={`/app/profile/${card.userId}/Summary`}
                      >
                        {card.user}
                      </Link>
                    ) : (
                      card.user
                    )}
                  </Typography>
                </Grid>
                <Grid sx={{ mb: 2 }}>
                  <Typography sx={{ display: "inline" }}>
                    User Email:{" "}
                  </Typography>
                  <Typography sx={{ display: "inline", fontWeight: "bold" }}>
                    {isPermission ? (
                      <Link
                        target="_blank"
                        href={`/app/profile/${card.userId}/Summary`}
                      >
                        {card.email}
                      </Link>
                    ) : (
                      card.email
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </HasPermissions>

      <HasPermissions permissions="See Cards Balance">
        <Card sx={{ p: 2, mb: 2 }}>
          <CardTitle>Card Balance</CardTitle>
          <CardContent>
            <Box sx={{ display: "flex", flexFlow: "wrap" }}>
              {"balance" in card &&
                card.balance.map((card) => {
                  return (
                    <DataCard title={card.balance} subTitle={card.symbol} />
                  );
                })}
            </Box>
          </CardContent>
        </Card>
      </HasPermissions>

      <HasPermissions permissions="See Cards Transactions">
        <Card sx={{ mb: 2 }}>
          <PaginationTable
            ref={paginationTableRef}
            apiEndpoint={GET_CARD_TRANSACTIONS}
            title="Card Transactions"
            //renderTopToolbar={renderTopToolbar}
            columns={columns}
            data={data.records}
            // onPaginationChange={setPagination}
            // state={{ pagination, loading: false }}
            // rowCount={data.count}
            initialFilters={{ cardId: cardId }}
            quarterRange
          />
        </Card>
      </HasPermissions>

      {showLimitsAndStatus ? (
        <>
          <HasPermissions permissions="See Cards Limits">
            <CardLimits cardId={cardId} />
          </HasPermissions>
          <br />
          <HasPermissions permissions="Change Cards Status">
            <CardsStatus cardId={cardId} setCard={setCard} />
          </HasPermissions>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default CardDetail;
