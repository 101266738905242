import { showMessage } from "components/Modal";
import {
  GET_ADMIN_DEFAULTS_STARTED,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILURE,
  GET_ADMIN_DEFAULTS_FAILURE,
  EDIT_WALLET_MINIMUM_BALANCE_STARTED,
  EDIT_WALLET_MINIMUM_BALANCE_SUCCESS,
  EDIT_WALLET_MINIMUM_BALANCE_FAILURE,
  GET_ADMINS_LIST_STARTED,
  GET_ADMINS_LIST_SUCCESS,
  GET_ADMINS_LIST_FAILURE,
  SET_Admin,
  GET_ADMIN_VIRTUAL_CARDS_SUCCESS,
  GET_ADMIN_VIRTUAL_CARDS_FAILURE,
  GET_ADMIN_VIRTUAL_CARDS_STARTED,
} from "redux/ActionTypes";
import {
  editAdminWalletMinimumBalanceAPI,
  getAdminBalanceAPI,
  getAdminFeesListAPI,
  getAdminInfoAPI,
  getAdminsListAPI,
  getAdminVirtualCardAPI,
  getAdminWalletAPI,
  getSuperAdminBalanceAPI,
} from "services/API/admins";
import { setAdminFeesAPI } from "services/API/users";

export const getAdminDefaultsStartedAction = () => ({
  type: GET_ADMIN_DEFAULTS_STARTED,
});

export const getAdminsSuccessAction = (payload) => ({
  type: GET_ADMIN_SUCCESS,
  payload,
});

export const getAdminsFailureAction = (message) => ({
  type: GET_ADMIN_FAILURE,
  payload: message,
});

export const getAdminDefaultsFailureAction = (message) => ({
  type: GET_ADMIN_DEFAULTS_FAILURE,
  payload: message,
});

export const editAdminWalletMinimumBalanceStartedAction = () => ({
  type: EDIT_WALLET_MINIMUM_BALANCE_STARTED,
});

export const editAdminWalletMinimumBalancesSuccessAction = (payload) => ({
  type: EDIT_WALLET_MINIMUM_BALANCE_SUCCESS,
  payload,
});

export const editAdminWalletMinimumBalancesFailureAction = (message) => ({
  type: EDIT_WALLET_MINIMUM_BALANCE_FAILURE,
  payload: message,
});

export const getAdminsListStartedAction = () => ({
  type: GET_ADMINS_LIST_STARTED,
});

export const getAdminsListSuccessAction = (payload) => ({
  type: GET_ADMINS_LIST_SUCCESS,
  payload,
});

export const getAdminsListFailureAction = (message) => ({
  type: GET_ADMINS_LIST_FAILURE,
  payload: message,
});

export const setAdmin = (admin) => ({
  type: SET_Admin,
  payload: admin,
});

export const getAdminWallet = ({ setData, adminId }) => {
  return async (dispatch) => {
    dispatch(getAdminDefaultsStartedAction());
    try {
      let response = await getAdminWalletAPI(adminId);

      if (!response.error) {
        dispatch(getAdminsSuccessAction());
        setData(response.data);
      } else {
        dispatch(getAdminsFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getAdminsFailureAction(error.error_description));
    }
  };
};

export const getAdminInfo = ({ setAdmin, adminId }) => {
  return async (dispatch) => {
    dispatch(getAdminDefaultsStartedAction());
    try {
      let response = await getAdminInfoAPI({ adminId });
      if (!response.error) {
        dispatch(getAdminsSuccessAction());
        setAdmin(response.data);
      } else {
        dispatch(getAdminsFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getAdminsFailureAction(error.error_description));
    }
  };
};

export const getAdminFeesListAction = (adminId, setFees) => {
  return async (dispatch) => {
    try {
      let response = await getAdminFeesListAPI(adminId);
      if (!response.error) {
        setFees(response.data);
        dispatch(getAdminsSuccessAction(response.data));
      } else {
        dispatch(getAdminsFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getAdminsFailureAction(error.error_description));
    }
  };
};

export const setAdminFeesRequestAction = (data, setLoading, setFee) => {
  return async (dispatch) => {
    setLoading(true);
    dispatch(getAdminDefaultsStartedAction());
    try {
      let response = await setAdminFeesAPI(data);
      if (!response.error) {
        setLoading(false);
        setFee(response.data);
        showMessage({
          status: "success",
        });
      } else {
        setLoading(false);
        dispatch(
          getAdminDefaultsFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description.message,
        status: "error",
      });
      dispatch(getAdminDefaultsFailureAction(error.error_description));
    }
  };
};

export const getAdminBalanceAction = (walletId, setAdminBalance) => {
  return async (dispatch) => {
    try {
      let response = await getAdminBalanceAPI(walletId);
      if (!response.error) {
        if (response.data <= 0) setAdminBalance(0);
        setAdminBalance(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getSuperAdminBalanceAction = (
  walletId,
  setAdminBalance,
  setLoading
) => {
  setLoading(true);
  return async (dispatch) => {
    try {
      let response = await getSuperAdminBalanceAPI(walletId);
      if (!response.error) {
        if (response.data <= 0) setAdminBalance(0);
        setAdminBalance(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
};

export const editAdminWalletMinimumBalanceRequestAction = ({
  walletId,
  minBalance,
  setData,
  adminId,
}) => {
  return async (dispatch) => {
    dispatch(editAdminWalletMinimumBalanceStartedAction());
    try {
      let response = await editAdminWalletMinimumBalanceAPI({
        walletId,
        minBalance,
      });
      if (!response.error) {
        dispatch(editAdminWalletMinimumBalancesSuccessAction());
        // dispatch(getUserWallet({ userId, setData }));
      } else {
        dispatch(
          editAdminWalletMinimumBalancesFailureAction(
            response.data.error_description
          )
        );
      }
    } catch (error) {
      dispatch(
        editAdminWalletMinimumBalancesFailureAction(error.error_description)
      );
    }
  };
};

export const getAdminsListRequestAction = () => {
  return async (dispatch) => {
    dispatch(getAdminsListStartedAction());
    try {
      let response = await getAdminsListAPI();
      if (!response.error) {
        dispatch(getAdminsListSuccessAction({ adminList: response.data }));
      } else {
        dispatch(getAdminsListFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getAdminsListFailureAction(error.error_description));
    }
  }};

export const getAdminVirtualCards = ({ setData, adminId , setLoading }) => {
  setLoading(true);
  return async (dispatch) => {
    dispatch(getAdminVirtualCardsStartedAction());
    try {
      let response = await getAdminVirtualCardAPI({ adminId });
      if (!response.error) {
        dispatch(getAdminVirtualCardsSuccessAction());
        setData(response.data);
        setLoading(false);
      } else {
        dispatch(getAdminVirtualCardsFailureAction(response.data.error_description));
        setLoading(false);
      }
    } catch (error) {
      dispatch(getAdminVirtualCardsFailureAction(error.error_description));
      setLoading(false);
    }
  };
};

export const getAdminVirtualCardsStartedAction = () => ({
  type: GET_ADMIN_VIRTUAL_CARDS_STARTED,
});

export const getAdminVirtualCardsSuccessAction = (payload) => ({
  type: GET_ADMIN_VIRTUAL_CARDS_SUCCESS,
  payload,
});
export const getAdminVirtualCardsFailureAction = (payload) => ({
  type: GET_ADMIN_VIRTUAL_CARDS_FAILURE,
  payload,
});