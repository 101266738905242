import React, { useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Link } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "components/DropDown";
import { getAgentsListRequestAction } from "redux/actions/agentActions";
import { GET_PHYSICAL_CARDS } from "config/apiRoutes";
import PaginationTable from "components/PaginationTable";
import { getCardDetails } from "redux/actions/usersActions";
import { sendEmailAction } from "redux/actions/cardActions";
import { showMessage } from "components/Modal";
import { LoadingButton } from "@mui/lab";
import { superAdmin } from "constant";
// --------------------------------------------------------------------------------
const PhysicalCards = () => {
  const dispatch = useDispatch();

  const [agent, setAgent] = useState(0);
  const agentsList = useSelector((state) => state.agent.agentsList);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [card, setCard] = useState();
  const [loadingCard, setLoadingCard] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);

  const paginationTableRef = useRef();

  const { physicalCards , roles } = useSelector((state) => ({
    physicalCards: state.card.physicalCards,
    roles: state.auth.user.roles,
  }));

    const isSuperAdmin = roles.some((role) => role.name === superAdmin);

  useEffect(() => {
    dispatch(getAgentsListRequestAction());
  }, []);

  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, [agent]);

  const handleResendEmail = (cardId) => {
    setLoadingCard((prevState) => ({
      ...prevState,
      [cardId]: true,
    }));

    dispatch(
      getCardDetails({
        setCard,
        cardId,
        onSuccess: () => {
          dispatch(
            sendEmailAction({
              token: card.token,
              un: card.un,
              persian_user_name: card.persianUserName,
              english_user_name: card.englishUserName,
              balance: card.balance,
              postal_code: card.postalCode,

              onSuccess: () => {
                setShowConfirm(false);
                showMessage({
                  status: "success",
                  description: "Email sent successfully",
                });
                setLoadingCard((prevState) => ({
                  ...prevState,
                  [cardId]: false,
                }));
              },
              onError: () => {
                showMessage({
                  status: "error",
                  description: "Failed to send email",
                });
                setLoadingCard((prevState) => ({
                  ...prevState,
                  [cardId]: false,
                }));
              },
            })
          );
        },
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "cardId",
        header: "Pep Card ID",
        Cell: ({ cell, row }) => (
          <Link target="_blank" to={`/cards/${row.original.id}`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "agent",
        header: "Agent",
      },
      {
        accessorKey: "user",
        // accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "User",
        Cell: ({ cell, row }) => (
          <Link
            target="_blank"
            to={`/app/profile/${row.original.userId}/Summary`}
          >
            {cell.getValue()}
          </Link>
        ),
        mergedKeys: ["firstName", "lastName"],
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        size: 200,
        Cell: ({ cell, row }) => (
          <Link
            target="_blank"
            to={`/app/profile/${row.original.userId}/Summary`}
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "cardNumber",
        header: "Card Number",
        Cell: ({ cell, row }) => (
          <Link target="_blank" to={`/cards/${row.original.id}`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "fee",
        header: "Fee",
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: "brand",
        header: "Brand",
        size: 100,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 100,
      },
      {
        accessorKey: "modifiedBy",
        header: "Modified by",
        size: 100,
      },
      {
        accessorKey: "createdTime",
        header: "Created Time",
      },
      {
        accessorKey: "lastUpdate",
        header: "Last Update",
      },
      {
        accessorKey: "operation",
        header: "Operation",
        // Add unique button event handler
        Cell: ({ row }) => (
          <LoadingButton
            onClick={() => handleResendEmail(row.original.id)}
            loading={loadingCard[row.original.id]}
            variant="contained"
            color="primary"
            disabled={row.original.agent?.is_premium === 0}
          >
            Resend Email
          </LoadingButton>
        ),
      },
    ],
    [loadingCard]
  );

  return (
    <PaginationTable
      ref={paginationTableRef}
      title="Physical Cards"
      apiEndpoint={GET_PHYSICAL_CARDS}
      renderTopToolbar={
        isSuperAdmin && (
          <Grid item>
          <Box sx={{ width: 238 }}>
            <DropDown
              label="Agent"
              schema={{ label: "name", value: "id" }}
              items={
                agentsList
                  ? [{ name: "ALL", id: 0 }, ...agentsList]
                  : [{ name: "ALL", id: 0 }]
              }
              value={agent}
              onChange={(event) => setAgent(event.target.value)}
            />
          </Box>
        </Grid>
        )

      }
      columns={columns}
      initialFilters={{ agentId: agent }}
    />
  );
};

// export default withDateRangePicker(PhysicalCards);

export default PhysicalCards;
