import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import AppDatePicker from "components/AppDatePicker";
import CustomTable from "components/CustomTable";
import Dayjs from "dayjs";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CsvBuilder } from "filefy";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_BASE_URL } from "config/apiRoutes";
import AppTextInput from "components/AppTextInput";

const PaginationTable = forwardRef(
  (
    {
      title,
      columns,
      apiEndpoint,
      initialFilters = {},
      datePicker = true,
      keywordSearch,
      renderTopToolbar,
      quarterRange,
    },
    ref
  ) => {
    const [data, setData] = useState({ records: [], count: 0 });
    const [tableLoading, setTableLoading] = useState(true);
    //   const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);
    const [from, setFrom] = useState(Dayjs().subtract(7, "day"));
    const [to, setTo] = useState(Dayjs().add(1, "day"));
    const [keyword, setKeyword] = useState("");
    const [debouncedKeyword, setDebouncedKeyword] = useState("");
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });

    const token = useSelector((state) => state.auth.token);

    const filteredInitialFilters = Object.keys(initialFilters).reduce(
      (acc, key) => {
        if (initialFilters[key] !== 0) acc[key] = initialFilters[key];
        return acc;
      },
      {}
    );

    useEffect(() => {
      fetchData();
    }, [from, to, pagination.pageIndex, pagination.pageSize, debouncedKeyword]);

    useEffect(() => {
      // Set a delay (e.g., 500ms) before updating the debounced value
      const handler = setTimeout(() => {
        setDebouncedKeyword(keyword);
      }, 500);

      // Clear the timeout if the keyword changes before the delay ends
      return () => {
        clearTimeout(handler);
      };
    }, [keyword]);

    // Expose the fetchData function to the parent component via ref
    useImperativeHandle(ref, () => ({
      refetch: fetchData, // The parent can call refetch to trigger this function
    }));

    const fetchData = async () => {
      setTableLoading(true);

      // Construct query params
      const keys = Object.keys(filteredInitialFilters);
      const values = Object.values(filteredInitialFilters);
      const queryParams = keys
        .map((key, index) => `${key}=${values[index]}`)
        .join("&");

      const dateQuery = datePicker
        ? `&from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}${
            queryParams ? `&${queryParams}` : ""
          }`
        : "";
      const keywordQuery = keywordSearch ? `&keyword=${keyword}` : "";

      try {
        const response = await axios.get(
          `${API_BASE_URL}/${apiEndpoint}?limit=${pagination.pageSize}&offset=${
            pagination.pageIndex + 1
          }${dateQuery}${keywordQuery}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // Process data with merged keys
        const filteredData = response.data.data.records.map((item) => {
          columns.forEach((column) => {
            if (column.mergedKeys) {
              item[column.accessorKey] = column.mergedKeys
                .map((key) => item[key] || "")
                .filter(Boolean) // Remove empty strings
                .join(" "); // Join with space
            }
          });
          return item;
        });

        setTableLoading(false);
        setData({ count: response.data.data.count, records: filteredData });
      } catch (error) {
        setTableLoading(false);
        console.error("Error fetching data:", error);
        throw error;
      }
    };

    const fetchPage = async (page) => {
      setLoading(true);

      let keys = Object.keys(filteredInitialFilters);
      let values = Object.values(filteredInitialFilters);
      let queryParams = `${keys
        .map((key, index) => `${key}=${values[index]}`)
        .join("&")}`;
      let dateQuery = datePicker
        ? `from=${from.format("YYYY-MM-DD")}&to=${to.format("YYYY-MM-DD")}${
            queryParams ? `&${queryParams}` : ""
          }`
        : "";
      let keywordQuery = keywordSearch ? `&keyword=${keyword}` : "";

      try {
        const response = await axios.get(
          `${API_BASE_URL}/${apiEndpoint}?${dateQuery}${keywordQuery}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const filteredData = response.data.data.records.map((item) => {
          columns.forEach((column) => {
            if (column.mergedKeys) {
              item[column.accessorKey] = column.mergedKeys
                .map((key) => item[key] || "")
                .filter(Boolean) // Remove empty strings
                .join(" "); // Join with space
            }
          });
          return item;
        });
        setLoading(false);
        return filteredData;
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
        throw error;
      }
    };

    const exportToCSV = async () => {
      try {
        const allData = await fetchPage();

        // Check if data is valid
        if (!Array.isArray(allData) || allData.length === 0) {
          console.error(
            "Fetched data is not in the expected format or is empty."
          );
          return;
        }

        // Extract column keys and names
        const columnKeys = columns
          .filter((item) => allData[0].hasOwnProperty(item.accessorKey))
          .map((item) => item.accessorKey);

        const columnNames = columns
          .filter((item) => allData[0].hasOwnProperty(item.accessorKey))
          .map((item) => item.header);

        // Extract rows
        const rows = allData.map((row) => columnKeys.map((col) => row[col]));

        // Build and export the CSV file
        new CsvBuilder(`${title} - ${to.format("YYYY-MM-DD")}`)
          .setDelimeter(",")
          .setColumns(columnNames)
          .addRows(rows)
          .exportFile();
      } catch (error) {
        console.error("Error exporting CSV:", error);
      }
    };

    return (
      <CustomTable
        title={title}
        data={data.records}
        columns={columns}
        renderTopToolbar={
          <Grid container spacing={2} sx={{ px: 2, pb: 2 }}>
            {keywordSearch && (
              <Grid item>
                <AppTextInput
                  label="Search"
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </Grid>
            )}
            <Grid item>
              <AppDatePicker
                label="From"
                value={from}
                onChange={(e) => setFrom(e)}
                maxDate={quarterRange && Dayjs(from).add(3, "month")}
                minDate={
                  quarterRange
                    ? Dayjs(to).subtract(3, "month").add(1, "day")
                    : null
                }
              />
            </Grid>
            <Grid item>
              <AppDatePicker
                label="To"
                value={to}
                onChange={(e) => setTo(e)}
                minDate={quarterRange && Dayjs(to).subtract(3, "month")}
                maxDate={
                  quarterRange
                    ? Dayjs(from).add(3, "month").subtract(1, "day")
                    : null
                }
              />
            </Grid>
            {renderTopToolbar}
            <Grid item sx={{ ml: "auto" }}>
              <LoadingButton
                type="submit"
                onClick={exportToCSV}
                variant="contained"
                color="primary"
                loading={loading}
                startIcon={<FileDownloadOutlinedIcon />}
              >
                CSV
              </LoadingButton>
            </Grid>
          </Grid>
        }
        onPaginationChange={setPagination}
        state={{ pagination, isLoading: tableLoading }}
        rowCount={data.count}
      />
    );
  }
);

export default PaginationTable;