import {
  forgotPasswordFirstStepAPI,
  forgotPasswordSecondStepAPI,
  forgotPasswordThirdStepAPI,
  loginAPI,
  logoutAPI,
  verifyAPI,
} from "services/API/Auth";
import {
  FORGOT_PASSWORD_FIRST_STEP_FAILURE,
  FORGOT_PASSWORD_FIRST_STEP_STARTED,
  FORGOT_PASSWORD_FIRST_STEP_SUCCESS,
  FORGOT_PASSWORD_SECOND_STEP_FAILURE,
  FORGOT_PASSWORD_SECOND_STEP_STARTED,
  FORGOT_PASSWORD_SECOND_STEP_SUCCESS,
  FORGOT_PASSWORD_THIRD_STEP_FAILURE,
  FORGOT_PASSWORD_THIRD_STEP_STARTED,
  FORGOT_PASSWORD_THIRD_STEP_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_STARTED,
  VERIFY_SUCCESS,
} from "../ActionTypes";
import { showMessage } from "components/Modal";
// --------------------------------------------------------------------------------
export const loginRequestAction = ({ email, password, setStep }) => {
  return async (dispatch) => {
    dispatch(loginStartedAction());
    try {
      let response = await loginAPI({ email, password });

      if (!response.error) {
        dispatch(loginSuccessAction());
        setStep(1);
      } else {
        dispatch(loginFailureAction(response.data.error_description));
      }
    } catch (error) {

      let ErrorMessage = error.message;
      switch (true) {

        case error.message?.includes("Service Unavailable"):
            ErrorMessage =
                "Our website is currently undergoing scheduled maintenance to bring you a better experience. We appreciate your patience and understanding.";
            break;

        case Boolean(error.error_description?.message):
            ErrorMessage = error.error_description.message;
            break;

        case !error.response:
            ErrorMessage = "Network connection is not available";
            break;
    }

      showMessage({
        description: ErrorMessage,
        status: "error",
      });
      dispatch(loginFailureAction(error.error_description));
    }
  };
};

export const loginStartedAction = () => ({
  type: LOGIN_STARTED,
});

export const loginSuccessAction = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailureAction = (message) => ({
  type: LOGIN_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const verifyRequestAction = ({
  email,
  password,
  verifyCode,
  navigate,
}) => {
  return async (dispatch) => {
    dispatch(verifyStartedAction());
    try {
      let response = await verifyAPI({ email, password, verifyCode });
      if (!response.error) {
        dispatch(
          verifySuccessAction({
            token: response.data.token,
            user: response.data,
          })
        );
        let permissions = response.data.permissions;
        if (permissions.includes("See Dashboard")) navigate("/dashboard");
        else if (permissions.includes("See All Users"))
          navigate("/users/all-users");
        else navigate("/cards/virtual-cards");
      } else {
        dispatch(verifyFailureAction(response.data.error_description));
      }
    } catch (error) {
      showMessage({
        description: error.error_description.message,
        status: "error",
      });
      dispatch(verifyFailureAction(error.error_description));
    }
  };
};

export const verifyStartedAction = () => ({
  type: VERIFY_STARTED,
});

export const verifySuccessAction = (payload) => ({
  type: VERIFY_SUCCESS,
  payload,
});

export const verifyFailureAction = (message) => ({
  type: VERIFY_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const logoutRequestAction = ({ userID }) => {
  return async (dispatch) => {
    dispatch(logoutStartedAction());
    try {
      let response = await logoutAPI({ userID });
      if (!response.error) {
        dispatch(logoutSuccessAction());
      } else {
        logoutFailureAction(response.data.error_description);
      }
    } catch (error) {
      logoutFailureAction(error.error_description);
    }
  };
};

export const logoutStartedAction = () => ({
  type: LOGOUT_STARTED,
});

export const logoutSuccessAction = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailureAction = (message) => ({
  type: LOGOUT_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const forgotPasswordFirstStepRequestAction = ({ email, setStep }) => {
  return async (dispatch) => {
    dispatch(forgotPasswordFirstStepStartedAction());
    try {
      let response = await forgotPasswordFirstStepAPI({ email });
      if (!response.error) {
        dispatch(forgotPasswordFirstStepSuccessAction());
        setStep(1);
      } else {
        dispatch(
          forgotPasswordFirstStepFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      showMessage({
        description: error.error_description.email,
        status: "error",
      });
      dispatch(forgotPasswordFirstStepFailureAction(error.error_description));
    }
  };
};

export const forgotPasswordFirstStepStartedAction = () => ({
  type: FORGOT_PASSWORD_FIRST_STEP_STARTED,
});

export const forgotPasswordFirstStepSuccessAction = () => ({
  type: FORGOT_PASSWORD_FIRST_STEP_SUCCESS,
});

export const forgotPasswordFirstStepFailureAction = (message) => ({
  type: FORGOT_PASSWORD_FIRST_STEP_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const forgotPasswordSecondStepRequestAction = ({
  email,
  token,
  setStep,
}) => {
  return async (dispatch) => {
    dispatch(forgotPasswordSecondStepStartedAction());
    try {
      let response = await forgotPasswordSecondStepAPI({ email, token });
      if (!response.error) {
        dispatch(forgotPasswordSecondStepSuccessAction());
        setStep(2);
      } else {
        dispatch(
          forgotPasswordSecondStepFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      showMessage({
        description: "Invalid token. Please check.",
        status: "error",
      });
      dispatch(forgotPasswordSecondStepFailureAction(error.error_description));
    }
  };
};

export const forgotPasswordSecondStepStartedAction = () => ({
  type: FORGOT_PASSWORD_SECOND_STEP_STARTED,
});

export const forgotPasswordSecondStepSuccessAction = () => ({
  type: FORGOT_PASSWORD_SECOND_STEP_SUCCESS,
});

export const forgotPasswordSecondStepFailureAction = (message) => ({
  type: FORGOT_PASSWORD_SECOND_STEP_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const forgotPasswordThirdStepRequestAction = ({
  email,
  token,
  newPassword,
  confirmNewPassword,
  navigate,
}) => {
  return async (dispatch) => {
    dispatch(forgotPasswordThirdStepStartedAction());
    try {
      let response = await forgotPasswordThirdStepAPI({
        email,
        token,
        newPassword,
        confirmNewPassword,
      });
      if (!response.error) {
        dispatch(forgotPasswordThirdStepSuccessAction());
        showMessage({
          description: "Password successfully changed.",
          status: "success",
          onSubmit: () => {
            navigate("/login");
          },
        });
      } else {
        dispatch(
          forgotPasswordThirdStepFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(forgotPasswordThirdStepFailureAction(error.error_description));
    }
  };
};

export const forgotPasswordThirdStepStartedAction = () => ({
  type: FORGOT_PASSWORD_THIRD_STEP_STARTED,
});

export const forgotPasswordThirdStepSuccessAction = () => ({
  type: FORGOT_PASSWORD_THIRD_STEP_SUCCESS,
});

export const forgotPasswordThirdStepFailureAction = (message) => ({
  type: FORGOT_PASSWORD_THIRD_STEP_FAILURE,
  payload: message,
});
