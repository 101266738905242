import axiosMiddleware from "./axiosMiddleware";

export const getVirtualCardsListAPI = ({ limit, offset, from, to, agent }) => {
  return axiosMiddleware({
    method: "get",
    url: `/cards/list/virtual?limit=${limit}&offset=${offset}&agentId=${agent}&from=${from}&to=${to}`,
  });
};
export const getPhysicalCardsListAPI = ({ limit, offset, from, to, agent }) => {
  return axiosMiddleware({
    method: "get",
    url: `/cards/list/physical?limit=${limit}&offset=${offset}&agentId=${agent}&from=${from}&to=${to}`,
  });
};
export const getCardsLimitsAPI = (cardId) => {
  return axiosMiddleware({
    method: "post",
    url: "/card/get-limits",
    data: {
      cardId,
    },
  });
};
export const setCardsLimitsAPI = (data) => {
  return axiosMiddleware({
    method: "post",
    url: "/card/set-limits",
    data,
  });
};
export const updateCardsStatusAPI = (data) => {
  return axiosMiddleware({
    method: "post",
    url: "/card/update-status",
    data,
  });
};
export const getCardsStatusAPI = (cardId) => {
  return axiosMiddleware({
    method: "post",
    url: "/card/get-status",
    data: {
      cardId,
    },
  });
};

export const createCreditCardApi = ({
  currency,
  agent,
  amount,
  email,
  english_first_name,
  persian_first_name,
  english_last_name,
  persian_last_name,
}) => {
  let postData = {
    currency_id: currency,
    agent_id: agent,
    amount,
    email,
    english_first_name,
    persian_first_name,
    english_last_name,
    persian_last_name,
  };
  return axiosMiddleware({
    method: "post",
    url: "/cards/create-credit-card",
    data: postData,
  });
};
export const createPhysicalCardApi = ({
  currency,
  agent,
  amount,
  email,
  english_first_name,
  persian_first_name,
  english_last_name,
  persian_last_name,
  card_number,
  cvv2,
  expire_date,
}) => {
  let postData = {
    currency_id: currency,
    agent_id: agent,
    amount,
    email,
    english_first_name,
    persian_first_name,
    english_last_name,
    persian_last_name,
    card_number,
    cvv2,
    expire_date,
  };
  return axiosMiddleware({
    method: "post",
    url: "/cards/create-physical-card",
    data: postData,
  });
};
export const topupCardApi = ({ userId, walletId, amount }) => {
  let postData = {
    user_id: userId,
    wallet_id: walletId,
    amount: Number(amount).toFixed(2),
  };
  return axiosMiddleware({
    method: "post",
    url: "card/topup",
    data: postData,
  });
};
export const sendEmailApi = ({
  token,
  un,
  persian_user_name,
  english_user_name,
  balance,
  postal_code,
}) => {
  let postData = {
    token,
    un,
    persian_user_name,
    english_user_name,
    balance,
    postal_code,
  };
  return axiosMiddleware({
    method: "post",
    url: "/card/send-email",
    data: postData,
  });
};

export const updateTopupCardStatusApi = ({ reference_number }) => {
  let postData = {
    reference_number,
  };
  return axiosMiddleware({
    method: "post",
    url: "/update/topup-card/status",
    data: postData,
  });
};