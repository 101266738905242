import React from 'react';
import Checkbox from '@mui/material/Checkbox';

const AppCheckbox = ({ value, onChange, label , margin }) => {
  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        width: '300px',
        height: '40px',
        margin: margin ?? '10px'
      }}>
      <Checkbox
        checked={value}
        onChange={onChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        color="primary"
      />

      <span className="mx-2" size="lg">
        {label}
      </span>
    </div>
  );
};

export default AppCheckbox;
