import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CustomTable from "components/CustomTable";
import { getAdminVirtualCards } from "redux/actions/adminActions";
import { LoadingButton } from "@mui/lab";
import { CsvBuilder } from "filefy";
import { now } from "lodash";
import { Grid } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PaginationTable from "components/PaginationTable";
import { GET_ADMIN_VIRTUAL_CARDS } from "config/apiRoutes";

const AdminVirtualCards = ({ adminId }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const paginationTableRef = useRef();

  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, [adminId]);

  const columns = useMemo(
    () => [
      {
        header: "Pep Card ID",
        accessorKey: "cardId",
        Cell: ({ cell, row }) => (
          <Link target="_blank" to={`/cards/${row.original.id}`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "Masked Card Number",
        accessorKey: "cardNumber",
      },
      {
        header: "Brand",
        accessorKey: "brand",
      },
      {
        header: "Card Name",
        accessorKey: "name",
      },
      {
        header: "Status",
        accessorKey: "status",
      },
      {
        header: "Created Time",
        accessorKey: "createdTime",
      },
    ],
    []
  );

  return (
    <PaginationTable
      ref={paginationTableRef}
      title="Agent Virtial Card List"
      apiEndpoint={GET_ADMIN_VIRTUAL_CARDS}
      columns={columns}
      initialFilters={{ adminId: adminId }}
    />
  );
};

export default AdminVirtualCards;
