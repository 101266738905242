import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserTransactions } from "redux/actions/usersActions";
import { camelCaseToTitleCase } from "constants/usefulFunctions";
import CustomTable from "components/CustomTable";
import withDateRangePicker from "HOC/withDateRangePicker";
import PaginationTable from "components/PaginationTable";
import { GET_AGENT_TRANSACTION_LIST } from "config/apiRoutes";

function AdminTransactions({ adminId }) {
  const dispatch = useDispatch();

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const paginationTableRef = useRef();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [data, setData] = useState({ records: [] });

  // useEffect(() => {
  //   fetch();
  // }, [pagination.pageIndex, pagination.pageSize, from, to]);

  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, []);

  // const fetch = () => {
  //   dispatch(
  //     getUserTransactions({
  //       setData,
  //       userId,
  //       from,
  //       to,
  //       limit: pagination.pageSize,
  //       offset: pagination.pageIndex + 1,
  //     })
  //   );
  // };

  const columns = useMemo(
    () => [

      {
        accessorKey: "event",
        header: "Event",
        Cell: ({ cell, row }) => camelCaseToTitleCase(row.original.event),
      },
      {
        accessorKey: "amount",
        header: "Amount",
      },
      {
        accessorKey: "wallet",
        header: "Currency",
      },
      {
        accessorKey: "type",
        header: "Type",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "tracking_code",
        header: "Tracking Code",
      },
      {
        accessorKey: "date",
        header: "Date",
      },
    ],
    []
  );

  return (
    <PaginationTable
      ref={paginationTableRef}
      apiEndpoint={GET_AGENT_TRANSACTION_LIST}
      title="Agent Transactions"
      columns={columns}
      data={data.records}
      // onPaginationChange={setPagination}
      // state={{ pagination, loading: false }}
      // rowCount={data.count}
      //renderTopToolbar={renderTopToolbar}
      initialFilters={{ adminId: adminId }}
      //queryParams={{ adminId }}
    />
  );
}

export default  AdminTransactions;
