import React, { useEffect, useState } from "react";
import {Card,CardContent, Box, Grid, Typography,Link } from "@mui/material";
import {CardTitle} from "reactstrap";
import { useDispatch } from "react-redux";
import DataCard from "components/DataCard";
import { getAgentsBalancesAction } from "redux/actions/agentActions";
// --------------------------------------------------------------------------------
const AgentsBalances = () => {
  const dispatch = useDispatch();
  const [balances, setBalances] = useState([]);

  useEffect(() => {
    dispatch(getAgentsBalancesAction({ setBalances }));
  }, [setBalances, dispatch]);
  return (
    <Card sx={{p:2,mb:2}}>
      <CardTitle>Agents Balance</CardTitle>

      <CardContent sx={{ display: "flex", flexFlow: "wrap"}}>
        {balances.map((agent) => {
            return (
              <DataCard title={agent.balance} subTitle={agent.symbol} />
            );
        })}
      </CardContent>
    </Card>
  );
};

export default AgentsBalances;
