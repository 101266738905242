import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "components/DropDown";
import { showMessage } from "components/Modal";
import AppNumberInput from "components/AppNumberInput";
import { LoadingButton } from "@mui/lab";
import { getAgentsListRequestAction } from "redux/actions/agentActions";
import { createCreditCardAction } from "redux/actions/cardActions";
import CloseIcon from "@mui/icons-material/Close";
import { toFixedWithoutZeros } from "constants/usefulFunctions";
import {
  getAdminWallet,
  getSuperAdminBalanceAction,
} from "redux/actions/adminActions";
import { superAdmin } from "constant";
import { EMAIL_REGEX } from "util/regex";
import AppCheckbox from "components/AppCheckbox";

export const CreateCreditCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { agentsList, adminId, roles, adminAgent } = useSelector((state) => ({
    agentsList: state.agent.agentsList,
    adminId: state.auth.user.id,
    roles: state.auth.user.roles,
    adminAgent: state.auth.user.agents,
  }));
  const [english_first_name, setEnglishFirstName] = useState("");
  const [persian_first_name, setPersianFirstName] = useState("");
  const [english_last_name, setEnglishLastName] = useState("");
  const [persian_last_name, setPersianLastName] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState(0);
  const [agent, setAgent] = useState(adminAgent[0] && adminAgent[0].id);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [agentBalance, setAgentBalance] = useState(0);
  const [errors, setErrors] = useState({});
  // const [isAmountEnabled, setIsAmountEnabled] = useState(false);

  const isSuperAdmin = roles.some((role) => role.name === superAdmin);

  useEffect(() => {
    dispatch(getAgentsListRequestAction());
    dispatch(getAdminWallet({ adminId: adminId, setData: setWallets }));
  }, []);

  const getAgentById = (id) => {
    const agent = agentsList.find((agent) => agent.id === id) || null;
    return agent ? agent.name : null;
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  const handleSubmit = () => {
    // if (handleValidation()) {
    setLoading(true);
    dispatch(
      createCreditCardAction({
        currency: currency.currency_id,
        agent,
        amount,
        email,
        english_first_name,
        persian_first_name,
        english_last_name,
        persian_last_name,
        setLoading,
        onSuccess: () => {
          setShowConfirm(false);
          showMessage({
            status: "success",
            description:
              "Your virtual card has been created and sent to your email.",
            onSubmit: () => {
              navigate("/cards/virtual-cards");
            },
          });
        },
      })
    );
    // }
  };

  const handleEnglishChange = (setter) => (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^A-Za-z\s]/g, "");
    setter(filteredValue);
  };

  const handlePersianChange = (setter) => (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^آ-ی\s]/g, "");
    setter(filteredValue);
  };

  const renderDialogConfirm = (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showConfirm}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Request New Virtual Card
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>
          English First Name:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {english_first_name}
          </Typography>{" "}
        </Typography>
        <Typography gutterBottom>
          English Last Name:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {english_last_name}
          </Typography>{" "}
        </Typography>
        <Typography gutterBottom>
          Persian First Name:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {persian_first_name}
          </Typography>{" "}
        </Typography>
        <Typography gutterBottom>
          Persian Last Name:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {persian_last_name}
          </Typography>{" "}
        </Typography>
        <Typography gutterBottom>
          Email:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {email}
          </Typography>{" "}
        </Typography>

        {/* {isAmountEnabled && ( */}
        <>
          <Typography gutterBottom>
            Currency:{" "}
            <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
              {currency.currency}
            </Typography>{" "}
          </Typography>
          <Typography gutterBottom>
            Amount:{" "}
            <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
              {toFixedWithoutZeros(amount)}
            </Typography>
          </Typography>
        </>
        {/* )} */}
        {isSuperAdmin && (
          <Typography gutterBottom>
            Agent:{" "}
            <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
              {getAgentById(agent)}
            </Typography>{" "}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ boxShadow: "none" }}
          // onClick={handleClose}
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <Card>
      <CardHeader title="Request New Virtual Card" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <TextField
              label="First Name (English)"
              onChange={handleEnglishChange(setEnglishFirstName)}
              value={english_first_name}
              fullWidth
              // error={hasError && !!errors.email}
              // helperText={hasError && errors.email || ""}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Last Name (English)"
              onChange={handleEnglishChange(setEnglishLastName)}
              value={english_last_name}
              fullWidth
              // error={hasError && !!errors.email}
              // helperText={hasError && errors.email || ""}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              dir="rtl"
              label="First Name (Persian)"
              onChange={handlePersianChange(setPersianFirstName)}
              value={persian_first_name}
              fullWidth
              // error={hasError && !!errors.email}
              // helperText={hasError && errors.email || ""}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              dir="rtl"
              label="Last Name (Persian)"
              onChange={handlePersianChange(setPersianLastName)}
              value={persian_last_name}
              fullWidth
              // error={hasError && !!errors.email}
              // helperText={hasError && errors.email || ""}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              fullWidth
              error={errors.email}
              helperText={errors.email}
              onFocus={() => setErrors({})}
            />
          </Grid>
          {isSuperAdmin && (
            <Grid item xs={12} lg={3}>
              <DropDown
                label="Agent"
                schema={{ label: "name", value: "id" }}
                items={agentsList ? [...agentsList] : []}
                onChange={(event) => setAgent(event.target.value)}
                value={agent}
                // error={hasError && !!errors.agent}
                // helperText={hasError && errors.agent || ""}
              />
            </Grid>
          )}
          {/* <Grid item xs={12}>
            <AppCheckbox
              label="Enable Amount"
              onChange={(e) => {
                console.log(e);
                setIsAmountEnabled(!isAmountEnabled);
              }}
              on
            />
          </Grid> */}
          {/* {isAmountEnabled && ( */}
          <>

          <Grid item xs={12} lg={3}>
              <DropDown
                label="Currency"
                schema={{ label: "currency", value: "currency_id" }}
                items={wallets}
                onChange={(event) => {
                  let id = event.target.value;
                  let currency = wallets.find(
                    (wallet) => wallet.currency_id === id
                  );
                  setCurrency(currency);
                  dispatch(
                    getSuperAdminBalanceAction(
                      currency.id,
                      setAgentBalance,
                      setLoading
                    )
                  );
                }}
                value={currency.currency_id}
                // error={hasError && !!errors.currency}
                // helperText={hasError && errors.currency || ""}
              />
            </Grid>

            {currency && (
              <Grid item xs={12} lg={3}>
                <AppNumberInput
                  thousandSeparator={true}
                  label="Amount"
                  onChange={(e) => setAmount(e.target.value)}
                  value={amount}
                  fullWidth
                  // error={hasError && !!errors.amount}
                  // helperText={hasError && errors.amount || ""}
                  helperText={
                    isSuperAdmin
                      ? null
                      : `Balance: ${agentBalance} ${currency.currency}`
                  }
                />
              </Grid>
            )}


          </>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          //onClick={handleSubmit}
          onClick={() => {
            if (!EMAIL_REGEX.test(email)) {
              setErrors({ email: "The email address you entered is invalid." });
              return;
            }
            setShowConfirm(true);
          }}
          disabled={
            !persian_first_name ||
            !persian_last_name ||
            !english_first_name ||
            !english_last_name ||
            !email ||
            !agent ||
            !currency ||
            Number(amount) <= 0 ||
            (!isSuperAdmin && agentBalance < Number(amount))
          }
        >
          Submit
        </LoadingButton>
      </CardActions>
      {renderDialogConfirm}
    </Card>
  );
};

export default CreateCreditCard;
